






import Vue from 'vue';

export default Vue.extend({
    name: 'FormRow',
    props: {
        fullWidth: {
            type: Boolean,
            default: false,
        },
    },
});
