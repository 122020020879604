import Vue, { PropType } from 'vue';
import {
    ValidationGroups,
    ValidationProperties,
    VueConstructor,
} from 'vue/types/vue';
import { Validation } from 'vuelidate';
import {
    ValidationError,
    getErrorsByValidation,
} from '@/services/form/validationErrors';
import type { ValidationsCommonType } from '@/services/form/validations';

export interface FormFieldVue {
    readonly hasErrors: boolean;
    readonly errors: ValidationError[];
    registerFormInputComponent(formInputComponent: Vue): void;
}

export interface FormInputComponent<TModel, TContent, TFormType = void>
    extends Vue {
    readonly content: TContent;
    readonly model: TModel;
    readonly formModel: TFormType;
    readonly validations:
        | (Validation & ValidationGroups & ValidationProperties<unknown>)
        | null;
    readonly hasErrors: boolean;
    readonly errors: ValidationError[];
    readonly formField: FormFieldVue | null;
    readonly formFieldHasErrors: boolean;
    readonly formFieldErrors: ValidationError[];
    readonly showInfoIcon: boolean;
    readonly showSummary: boolean;
}

// eslint-disable-next-line @typescript-eslint/comma-dangle
export function getFormInputMixin<
    TModel,
    TContent,
    TConfiguration = void,
    TFormType = void
>(): VueConstructor<FormInputComponent<TModel, TContent, TFormType>> {
    return Vue.extend({
        inject: {
            injectedFormField: {
                from: 'formField',
                default: null as (Vue & FormFieldVue) | null,
            },
        },
        props: {
            content: {
                type: Object as PropType<TContent>,
                default: () => {
                    return {} as TContent;
                },
            },
            configuration: {
                type: Object as PropType<TConfiguration>,
                default: () => ({} as TConfiguration),
            },
            model: {
                type: Object as PropType<TModel>,
                default: () => {
                    return {} as TModel;
                },
            },
            formModel: {
                type: Object as PropType<TFormType>,
                default: () => ({} as TFormType),
            },
            validations: {
                type: Object as PropType<
                    | (Validation &
                          ValidationGroups &
                          ValidationProperties<unknown>)
                    | null
                >,
                default: null,
            },
            showInfoIcon: {
                type: Boolean,
                default: false,
            },
            showSummary: {
                type: Boolean,
                default: false,
            },
        },
        computed: {
            formField(this: {
                injectedFormField: FormFieldVue;
            }): FormFieldVue | null {
                return this.injectedFormField || null;
            },
            formFieldHasErrors(): boolean {
                return !!this.formField && this.formField.hasErrors;
            },
            formFieldErrors(): ValidationError[] {
                return this.formField ? this.formField.errors : [];
            },
            hasErrors(): boolean {
                return !!this.validations && this.validations.$anyError;
            },
            errors(): ValidationError[] {
                if (!this.validations || !this.hasErrors) {
                    return [];
                }
                return getErrorsByValidation(
                    this.validations as Validation & ValidationGroups,
                );
            },
            errorClass(): string | null {
                return this.hasErrors ? 'has-errors' : null;
            },
            /**
             * Use this for formList item validations.
             * Validations of formList items are dependent of its instances.
             * Since we cannot use validations?.property in templates use validationsProps.property instead.
             */
            validationProps(): ValidationsCommonType | unknown {
                return this.validations || {};
            },
        },
        watch: {
            formField: {
                handler(): void {
                    if (this.formField) {
                        this.formField.registerFormInputComponent(this);
                    }
                },
                immediate: true,
            },
            'model.value'(): void {
                if (this.validations) {
                    this.validations.$touch();
                }
            },
        },
    });
}
