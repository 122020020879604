/*
 * Returns true, if the given key indicates a function key of
 * the keyboard.
 */
export function isFunctionKey(key: string): boolean {
    return [
        'Escape',
        'Tab',
        'Enter',
        'Backspace',
        'Insert',
        'Delete',
        'ArrowTop',
        'ArrowRight',
        'ArrowBottom',
        'ArrowLeft',
        'Home',
        'End',
        'PageUp',
        'PageDown',
        'Control',
        'AltGraph',
    ].includes(key);
}

export const digitKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
