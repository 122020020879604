












import Vue, { PropType } from 'vue';
import InfoIconWrapper from '@/components/molecules/infoIconWrapper/InfoIconWrapper.vue';
import { InfoIconContent } from '@/api/interfaces/content/form/base/infoIconContent';
import { FormElement } from '@/types/forms/formElement';

export default Vue.extend({
    name: 'FieldWithInfoIcon',
    components: {
        InfoIconWrapper,
    },
    props: {
        content: {
            type: Object as PropType<InfoIconContent>,
            required: true,
        },
        model: {
            type: Object as PropType<FormElement>,
            default: () => ({} as FormElement),
        },
        showInfoIcon: {
            type: Boolean,
            default: false,
        },
        alignTop: {
            type: Boolean,
            default: false,
        },
        inlineElement: {
            type: Boolean,
            default: false,
        },
    },
});
