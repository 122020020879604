










import Vue from 'vue';
import { v4 as uuidv4 } from 'uuid';
import tippy, { Instance, roundArrow } from 'tippy.js';
import 'tippy.js/dist/svg-arrow.css';
import 'tippy.js/dist/border.css';

export default Vue.extend({
    name: 'InfoIcon',
    props: {
        text: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            id: uuidv4(),
            tippyBox: null as Instance | null,
        };
    },
    computed: {
        iconId(): string {
            return `infoicon-${this.id}`;
        },
        iconContent(): string {
            return `<div class="dea-rich-text">${this.text}</div>`;
        },
    },
    watch: {
        iconContent() {
            if (this.tippyBox) {
                this.tippyBox.setContent(this.iconContent);
            }
        },
    },
    mounted() {
        const icon = document.querySelector(`#${this.iconId}`);

        if (icon) {
            this.tippyBox = tippy(icon, {
                content: this.iconContent,
                allowHTML: true,
                interactive: true,
                arrow: roundArrow + roundArrow, // https://atomiks.github.io/tippyjs/v6/themes/#svg-arrow-1
                maxWidth: 500,
                placement: 'top',
                offset: [0, 4],
                onMount: this.setMaxHeight,
            });
        }
    },
    beforeDestroy: function () {
        this.tippyBox?.destroy();
    },
    methods: {
        setMaxHeight(): void {
            if (this.tippyBox) {
                const tippyElement = this.tippyBox.popper.querySelector(
                    '.tippy-box',
                );

                if (tippyElement) {
                    const contentBox = tippyElement.querySelector(
                        '.tippy-content',
                    );

                    if (contentBox) {
                        const {
                            height: referenceHeight,
                            top: referenceTop,
                            bottom: referenceBottom,
                        } = this.tippyBox.reference.getBoundingClientRect();
                        const windowHeight = window.innerHeight;
                        const isTop =
                            tippyElement.getAttribute('data-placement') ===
                            'top';
                        const calculatedMaxHeight = isTop
                            ? referenceTop - referenceHeight
                            : windowHeight - referenceBottom - referenceHeight;

                        (contentBox as HTMLElement).style.maxHeight = `${calculatedMaxHeight}px`;
                    }
                }
            }
        },
    },
});
