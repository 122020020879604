
















































import { getFormInputTextMixin } from '@/mixins/formInputTextMixin';
import { TextField } from '@/types/forms/fields/textField';
import { TextInputWithUnitContent } from '@/api/interfaces/content/form/base/textInputWithUnitContent';
import FieldWithInfoIcon from '@/components/molecules/fieldWithInfoIcon/FieldWithInfoIcon.vue';
import Icon from '@/components/atoms/icon/Icon.vue';

export default getFormInputTextMixin<
    TextField,
    TextInputWithUnitContent
>().extend({
    name: 'FormInputText',
    components: {
        FieldWithInfoIcon,
        Icon,
    },
    methods: {
        formatValueWithSeparator() {
            if (
                !this.validations ||
                !Object.prototype.hasOwnProperty.call(
                    this.validations,
                    'float',
                ) ||
                !this.model.value
            ) {
                return;
            }

            let { value } = this.model;
            let cursorPosition =
                document.activeElement === this.$el
                    ? (this.$el as HTMLInputElement).selectionStart
                    : null;

            value = value.replace(/\.+/g, '');
            value = value.replace(/\B(?=(\d{3})+(?!\d))/g, '.');

            this.model.value = value;

            if (cursorPosition !== null) {
                (this.$el as HTMLInputElement).setSelectionRange(
                    cursorPosition,
                    cursorPosition,
                );
            }
        },
    },
});
