import { PropType } from 'vue';
import { VueConstructor } from 'vue/types/vue';
import { compact as _compact } from 'lodash';
import { isFunctionKey } from '@/services/keys';
import { TextInputWithUnitContent } from '@/api/interfaces/content/form/base/textInputWithUnitContent';
import { FormInputComponent, getFormInputMixin } from './formInputMixin';
import { TextField } from '@/types/forms/fields/textField';
import { FormElement } from '@/types/forms/formElement';

// eslint-disable-next-line @typescript-eslint/comma-dangle
export interface FormInputTextComponent<
    TModel,
    TContent extends TextInputWithUnitContent
> extends FormInputComponent<TModel, TContent> {
    readonly minLength: number;
    readonly maxLength: number;
    readonly alignText: string;
    readonly type: string;
    readonly allowedCharacters: string[] | null;
    readonly forbiddenCharacters: string[] | null;
    readonly inputClasses: string[];
}

// eslint-disable-next-line @typescript-eslint/comma-dangle
export function getFormInputTextMixin<
    TModel extends FormElement,
    TContent extends TextInputWithUnitContent
>(): VueConstructor<FormInputTextComponent<TModel, TContent>> {
    return getFormInputMixin<TModel, TContent>().extend({
        props: {
            minLength: {
                type: Number,
                default: 0,
            },
            maxLength: {
                type: Number,
                default: 256,
            },
            alignText: {
                type: String,
                validator(value: string) {
                    return (
                        !value ||
                        (typeof value === 'string' &&
                            ['left', 'center', 'right', 'justify'].includes(
                                value,
                            ))
                    );
                },
            },
            type: {
                type: String,
                default: 'text',
            },
            allowedCharacters: {
                type: Array as PropType<string[] | null>,
                default: null,
            },
            forbiddenCharacters: {
                type: Array as PropType<string[] | null>,
                default: null,
            },
            inputEvents: {
                type: Object,
                default: null,
            },
            icon: {
                type: String,
                default: '',
            },
            handleBlurEvent: {
                type: Boolean,
                default: true,
            },
            focusable: {
                type: Object,
                default: null,
            },
            embedded: {
                type: Boolean,
                default: false,
            },
        },
        computed: {
            textAlignmentClass(): string | null {
                // naming all variants here for tailwind purge:
                // text-left text-center text-right text-justify
                return this.alignText ? `text-${this.alignText}` : null;
            },
            errorClass(): string | null {
                return this.hasErrors ? 'has-errors' : null;
            },
            embeddedClass(): string | null {
                return this.embedded ? 'embedded' : null;
            },
            readonlyClass(): string | null {
                return !this.model.userChangeable ? 'readonly' : null;
            },
            inputClasses(): string[] {
                return _compact([
                    this.textAlignmentClass,
                    this.errorClass,
                    this.embeddedClass,
                ]);
            },
        },
        watch: {
            focusable: function () {
                const input = this.$el.querySelector('input') as HTMLElement;
                if (input) {
                    input.focus();
                }
            },
        },
        methods: {
            onFocus(): void {
                this.$emit('form-input-focus');
            },
            onBlur(): void {
                if (this.handleBlurEvent && this.validations) {
                    this.validations.$touch();
                }

                let value = undefined;

                if (this.model instanceof TextField) {
                    value = this.model.value;
                }

                this.$emit('form-input-blur', value);
            },
            onKeydown(e: KeyboardEvent): void {
                if (this.forbiddenCharacters) {
                    if (this.forbiddenCharacters.includes(e.key)) {
                        e.preventDefault();
                        return;
                    }
                }
                if (this.allowedCharacters) {
                    if (e.altKey || e.ctrlKey || isFunctionKey(e.key)) {
                        return;
                    }
                    if (!this.allowedCharacters.includes(e.key)) {
                        e.preventDefault();
                        return;
                    }
                }
            },
        },
    });
}
