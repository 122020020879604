















































































import Vue, { PropType } from 'vue';
import InfoIconWrapper from '@/components/molecules/infoIconWrapper/InfoIconWrapper.vue';
import { FormInputPosition } from '@/enums/formInputPosition';
import { ValidationGroups, ValidationProperties } from 'vue/types/vue';
import { Validation } from 'vuelidate';
import {
    ValidationError,
    getErrorsByValidation,
} from '@/services/form/validationErrors';
import { uniqWith as _uniqWith } from 'lodash';
import { LabelContent } from '@/api/interfaces/content/form/base/labelContent';
import { FormInputComponent } from '@/mixins/formInputMixin';
import { FormField } from '@/types/forms/fields/formField';
import FormRow from '@/components/molecules/formRow/FormRow.vue';

export default Vue.extend({
    name: 'FormField',
    components: {
        FormRow,
        InfoIconWrapper,
    },
    provide(this: Vue) {
        return {
            formField: this,
        };
    },
    props: {
        content: {
            type: Object as PropType<LabelContent>,
            default: () => {
                return {} as LabelContent;
            },
        },
        model: {
            type: Object as PropType<FormField<unknown>>,
            default: () => {
                return new FormField<unknown>();
            },
        },
        validations: {
            type: Object as PropType<
                | (Validation &
                      ValidationGroups &
                      ValidationProperties<unknown>)
                | null
            >,
            default: null,
        },
        alignField: {
            type: String as PropType<FormInputPosition>,
            default: FormInputPosition.nextToLabel,
        },
        showSummary: {
            type: Boolean,
            default: false,
        },
        hideFieldInSummary: {
            // can be used to hide fields explicitly even if there is a summaryValue
            type: Boolean,
            default: false,
        },
        summaryValue: {
            type: [String, Number, Boolean],
            default: null,
        },
        fullWidth: {
            type: Boolean,
            default: false,
        },
        showInfoIcon: {
            type: Boolean,
            default: false,
        },
        labelAlignTop: {
            type: Boolean,
            default: false,
        },
        labelClasses: {
            type: String,
            default: null,
        },
        showAsterisk: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            formInputComponents: [] as FormInputComponent<unknown, unknown>[],
        };
    },
    computed: {
        alignAsSingleRow(): boolean {
            return this.alignField === FormInputPosition.nextToLabel;
        },
        hideLabel(): boolean {
            return this.alignField === FormInputPosition.singleLine;
        },
        isRequired(): boolean {
            return this.isRequiredValidation(this.validations);
        },
        hasErrors(): boolean {
            return !!this.validations && this.validations.$anyError;
        },
        errors(): ValidationError[] {
            if (!this.validations || !this.hasErrors) {
                return [];
            }
            return _uniqWith(
                this.formInputComponents.reduce((formErrors, formInput) => {
                    const formInputErrors = getErrorsByValidation(
                        formInput.validations as Validation & ValidationGroups,
                    );
                    for (const formInputError of formInputErrors) {
                        formErrors.push(formInputError);
                    }
                    return formErrors;
                }, getErrorsByValidation(this.validations as Validation & ValidationGroups)),
                (error1: ValidationError, error2: ValidationError) =>
                    error1.identifier === error2.identifier,
            );
        },
        label(): string {
            return `${this.content.label}${
                (this.isRequired || this.showAsterisk) && !this.showSummary
                    ? '*'
                    : ''
            }`;
        },
        subLabel(): string {
            return this.content.subLabel ?? '';
        },
        summaryLabel(): string {
            return this.content.summaryLabel || this.label;
        },
        summaryLabelColon(): string {
            return this.summaryLabel.charAt(this.summaryLabel.length - 1) ===
                ':'
                ? ''
                : ':';
        },
        summaryIsBoolean(): boolean {
            // summary value can be a string, number or boolean -- in case of boolean (aka checkbox),
            // we're supposed to only show the label
            return typeof this.summaryValue === 'boolean';
        },
    },
    methods: {
        registerFormInputComponent(
            formInputComponent: FormInputComponent<unknown, unknown>,
        ): void {
            this.formInputComponents.push(formInputComponent);
        },
        isRequiredValidation(
            validations:
                | (Validation &
                      ValidationGroups &
                      ValidationProperties<unknown>)
                | null,
        ): boolean {
            if (!validations) {
                return false;
            }
            if (
                'required' in validations ||
                'checked' in validations ||
                'requiredDate' in validations
            ) {
                return true;
            }
            return Object.keys(validations.$params).some(
                (validatorIdentifier) => {
                    const nestedValidations = validations[validatorIdentifier];
                    return (
                        typeof nestedValidations === 'object' &&
                        this.isRequiredValidation(
                            nestedValidations as Validation &
                                ValidationGroups &
                                ValidationProperties<unknown>,
                        )
                    );
                },
            );
        },
    },
});
