


























import Vue, { PropType } from 'vue';
import InfoIcon from '@/components/molecules/infoIcon/InfoIcon.vue';
import { InfoIconContent } from '@/api/interfaces/content/form/base/infoIconContent';
import { FormElement } from '@/types/forms/formElement';

export default Vue.extend({
    name: 'InfoIconWrapper',
    components: {
        InfoIcon,
    },
    props: {
        content: {
            type: Object as PropType<InfoIconContent>,
            default: () => {
                return {} as InfoIconContent;
            },
        },
        model: {
            type: Object as PropType<FormElement>,
            default: () => ({} as FormElement),
        },
        alignTop: {
            type: Boolean,
            default: false,
        },
        showInfoIcon: {
            type: Boolean,
            default: false,
        },
        inlineElement: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        conditionalInfo(): InfoIconContent | null {
            return this.content.conditionalInfo
                ? this.content.conditionalInfo[this.model.conditionalInfoKey]
                : null;
        },
    },
});
