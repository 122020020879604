var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.model.disabled && !_vm.model.hidden)?_c('FormRow',{attrs:{"full-width":_vm.fullWidth}},[(_vm.showSummary && _vm.summaryValue && !_vm.hideFieldInSummary)?_c('div',{staticClass:"mb-1"},[(_vm.summaryIsBoolean)?_c('div',[_c('span',{staticClass:"dea-rich-text font-medium",domProps:{"innerHTML":_vm._s(_vm.summaryLabel)}})]):_c('div',[_c('span',{staticClass:"dea-rich-text",domProps:{"innerHTML":_vm._s(_vm.summaryLabel)}}),_vm._v(_vm._s(_vm.summaryLabelColon)+" "),_c('span',{staticClass:"dea-rich-text font-medium",domProps:{"innerHTML":_vm._s(_vm.summaryValue)}})])]):_vm._e(),(!_vm.showSummary)?_c('div',{staticClass:"mb-6"},[_c('div',{class:{ 'md:flex': _vm.alignAsSingleRow }},[(!_vm.hideLabel)?_c('div',{class:{
                    'pr-2 mb-2 md:mb-0 md:w-1/3 md:flex md:flex-shrink-0 flex-wrap': _vm.alignAsSingleRow,
                    'pr-2 mb-2': !_vm.alignAsSingleRow,
                    'content-start md:items-start': _vm.labelAlignTop,
                    'content-center md:items-center': !_vm.labelAlignTop,
                }},[_c('InfoIconWrapper',{attrs:{"show-info-icon":_vm.showInfoIcon,"content":_vm.content,"model":_vm.model,"align-top":"","inline-element":""}},[_c('span',{staticClass:"dea-rich-text",class:_vm.labelClasses,domProps:{"innerHTML":_vm._s(_vm.label)}}),(_vm.subLabel)?_c('span',{staticClass:"w-full text-xs md:text-sm block dea-rich-text",domProps:{"innerHTML":_vm._s(_vm.subLabel)}}):_vm._e()])],1):_vm._e(),_c('div',{class:{
                    'md:flex-grow md:w-2/3': _vm.alignAsSingleRow && !_vm.hideLabel,
                    'has-errors': _vm.hasErrors,
                }},[_vm._t("default")],2)]),_vm._l((_vm.errors),function(error){return _c('div',{key:error.identifier,staticClass:"mt-2 text-error dea-rich-text",domProps:{"innerHTML":_vm._s(error.message)}})})],2):_vm._e()]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }