import { get as _get } from 'lodash';
import { ValidationGroups } from 'vue/types/vue';
import { Validation } from 'vuelidate';
import { contentServices } from '../contentService';
import { interpolate } from '../interpolationService';

export interface ValidationError {
    identifier: string;
    message: string;
}

function getValidationErrorMessage(
    identifier: string,
    placeholders?: Record<string, unknown>,
): string {
    if (!contentServices.form.content) {
        return identifier;
    }

    const errorMessage = _get(
        contentServices.form.content.validationErrorsContent,
        identifier,
    );

    return typeof errorMessage === 'string'
        ? interpolate(errorMessage, placeholders)
        : identifier;
}

export function getErrorsByValidation(
    validation: Validation & ValidationGroups,
): ValidationError[] {
    if (!validation || !validation.$error) {
        return [];
    }
    return Object.keys(validation.$params).reduce(
        (errors, validatorIdentifier) => {
            if (!validation[validatorIdentifier]) {
                errors.push({
                    identifier: validatorIdentifier,
                    message: getValidationErrorMessage(
                        validatorIdentifier,
                        validation.$params[validatorIdentifier],
                    ),
                });
            }
            return errors;
        },
        [] as ValidationError[],
    );
}

export interface ValidationErrorProvider {
    readonly errors: ValidationError[];
}
